@font-face {
  font-family: "SF PRO";
  src: url(../../font//SF-Pro.ttf) format("truetype");
  /* You can specify other font formats (e.g., woff, woff2) as needed */
}

.sizeTa {
  height: 200px;
  font-family: "SF PRo";
  transform: translateX(0px);
}
.Stbody {
  border-left: 1px solid #d0cdfe;
  border-right: 1px solid #d0cdfe;
  border-bottom: 1px solid #d0cdfe;
}
.child{
  font-size: 18.5px;

  @media (max-width: 1000px){
    font-size: 17px;
  }
  @media (max-width: 800px){
    font-size: 16px;
  }
  @media (max-width: 500px){
    font-size: 15px;
  }
  @media (max-width: 400px){
    font-size: 14px;
  }
}

.col {
  width: 605px;
  text-align: center;
  background-color: #d0cdfe;

  font-family: "SF PRo";
  height: 80px;
}
.col.front {
  border-top-left-radius: 40px;
  border-right: 1.7px solid #8084c8;
  border-bottom: 1.7px solid #8084c8;
}
.col.back {
  border-top-right-radius: 40px;
  border-bottom: 1.7px solid #8084c8;
}
.col.md {
  border-right: 1.7px solid #8084c8;
  border-bottom: 1.7px solid #8084c8;
}

.col.eachCl {
  border-top-right-radius: 1px solid;
  font-size: 19px;

  @media (max-width: 1000px) {
    font-size: 17.5px;
  }
  @media (max-width: 800px) {
    font-size: 16.5px;
  }
  @media (max-width: 500px) {
    font-size: 15.5px;
  }
  @media (max-width: 400px) {
    font-size: 14.5px;
  }
}

.publicBT.active {
  background-color: #6fbf77; 
}

.manageBtDisplay {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 10px;
}

.manageBT {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  flex-shrink: 0;
  cursor: pointer;
  @media (max-width: 800px){
    width: 34px;
    height: 34px;
  }
  @media (max-width: 500px){
    width: 32px;
    height: 32px;
  }
}
.manageBT svg{
  width: 20px;
  height: 20px;

  @media (max-width: 800px){
    width: 17px;
    height: 17px;
  }
  @media (max-width: 500px){
    width: 17px;
    height: 17px;
  }
}
.manageBT.logBT {
  background-color: #a5a2a2;
}

.manageBT.logBT:hover {
  background-color: #757373;
}

.manageBT.editBT {
  background-color: #faae49;
}

.manageBT.editBT:hover {
  background-color: #f09114;
}

.manageBT.deleteBT {
  background-color: #e65f56;
}

.manageBT.deleteBT:hover {
  background-color: #ef372a;
}

.fontGlobal {
  font-size: 28px;
}

.ScorePopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ScorePopup-Content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.8);
  height: 220px;
  width: 500px;
  font-weight: 500;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

.ScorePopup-ContentInner {
  background-color: #8084c8;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  width: 540px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  top: 30px;
  transform: translateY(-22px);
}

.ScorePopupButtons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 7px;
  padding-right: 7px;
}

.primaryButton {
  width: 110px;
  height: 33px;
  border-radius: 10px;
  border: 1px;
  background: linear-gradient(180deg, rgba(36, 43, 176, 0.4) 0%, #535adf 100%);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  margin: 0 10px;
  cursor: pointer;
  font-family: "SF Pro", sans-serif;
  /* transform: translateY(10px); */
}

.secondaryButton {
  width: fit-content;
  height: 33px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 17px;
  margin: 0 10px;
  cursor: pointer;
  font-family: "SF Pro", sans-serif;
  /* transform: translateY(10px); */
}

.tertiaryButton {
  width: 100px;
  height: 30px;
  border: 1px;
  font-size: 14px;
  margin: 0 10px;
  cursor: pointer;
  text-decoration: underline;
  font-family: "SF Pro", sans-serif;
  /* transform: translateY(10px); */
}
