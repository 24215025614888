@font-face {
  font-family: "SF PRO";
  src: url(../../font//SF-Pro.ttf) format("truetype");
  /* You can specify other font formats (e.g., woff, woff2) as needed */
}

body {
  margin: 0; /* Reset default body margin */
  padding: 0; /* Reset default body padding */
  position: relative;
}

.Scorecourseframewindow {
  position: absolute;
  top: calc(175px + 50%);
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  max-width: 96.6%;
  height: calc(87vh - 165px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  transition: width 0.4s ease, margin-left 0.4s ease;
}
.Scorecourseframewindow.shrink {
  width: calc(79vw); 
  margin-left: 122px; 
}

.frameChild{
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: 7vh;

  @media screen and  (max-height: 900px) {
    gap: 13px;
    margin-top: 5vh;
  }

  @media screen and  (max-height: 850px) {
    gap: 7px;
    margin-top: 5vh;
  }
  @media screen and  (max-height: 830px) {
    gap: 5px;
    margin-top: 4vh;
  }

  @media screen and  (max-height: 780px) {
    gap: 5px;
    margin-top: 2vh;
  }
}

.ScoreText {
  color: #696ca3;
  font-family: SF Pro;
  font-size: 30px;
  font-style: normal;
  font-weight: 590;
  line-height: normal;
  margin-left: 40px;
}

.ScoreTextBox {
  padding: 8px;
  border: 2px solid #6e71ae;
  background-color: #eeecec;
  border-radius: 3px;
  font-family: SF Pro;
  width: calc(58vw);
  font-size: 16px;
  transform: translateX(20px);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.ScoreInlineContainer {
  display: flex; 
  align-items: center;
  margin-bottom: 30px;
  transition: transform 0.3s ease;
}
.ScoreInlineContainer.buttonCanCon {
  margin-left: 150px;
}

.ScoreFileDescription {
  font-size: 18px;
  color: #000000;
  font-family: SF Pro;
  transition: transform 0.3s ease;
  margin: auto;
}


.ScoreDescriptionBox {
  padding: 18px;
  border: 1px;
  background-color: #faedca;
  border-radius: 5px;
  width: calc(58vw);
  height: fit-content;
  margin-left: 189px;
  margin-top: -10px;
  transition: transform 0.3s ease;
}
.ScoreDescriptionBox.DesBox3{
  margin-top: 10px;
  margin-bottom: 20px;
}

.ScoreCancelButton {
  font-family: "SF PRO";
  transform: translate(40px, 45px);
  width: 150px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 10px;
  border: #cd5340 solid 2px;
  background: #ffffff;
  bottom: 110px;
  cursor: pointer;
  display: flex;
  justify-content: right;
  align-items: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-indent: -35px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.ScoreCancelButtonInner {
  width: 45px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 0px 5px 5px 0px;
  background: #d13e10;
  transform: translateX(1px);
  bottom: 110px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
  text-indent: -15px;
  font-size: 20px;
  transition: background-color 0.3s ease;
}

.ScoreCancelButton:hover {
  background: #d13e10;
  transition: background-color 0.3s ease;
}

.ScoreCancelButton:hover .ScoreCancelText {
  color: #ffffff;
}

.ScoreCancelText {
  position: absolute;
  right: 65px;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  color: #cd5340;
  transition: color 0.3s ease;
}

.ScoreConfirmButton {
  font-family: "SF PRO";
  transform: translate(60px, 45px);
  width: 150px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 10px;
  border: #1b7744 solid 2px;
  background: #ffffff;
  bottom: 110px;
  cursor: pointer;
  display: flex;
  justify-content: right;
  align-items: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-indent: -35px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}
.ScoreConfirmButtonDisable{
  font-family: "SF PRO";
  transform: translate(60px, 45px);
  width: 150px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 10px;
  border: #aca9a9 solid 2px;
  background: #bcbebd;
  bottom: 110px;
  cursor: pointer;
  display: flex;
  justify-content: right;
  align-items: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-indent: -35px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  cursor:default;
}
.ScoreConfirmButtonInner.disable{
  cursor: default;
  background: #aca9a9;
}
.ScoreConfirmText.disable {
  color: rgb(151, 149, 149);
}



.ScoreConfirmButtonInner {

  width: 45px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 0px 5px 5px 0px;
  background: #23834b;
  bottom: 110px;
  cursor: pointer;
  display: flex;
  transform: translateX(1px);
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
  text-indent: -15px;
  font-size: 20px;
  transition: background-color 0.3s ease;
}



.ScoreConfirmText {
  position: absolute;
  right: 65px;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  color: #23834b;
  transition: color 0.3s ease;
}


.ScoreConfirmButton:hover {
  background: #23834b;
  transition: background-color 0.3s ease;
}

.ScoreConfirmButton:hover .ScoreConfirmText {
  color: #ffffff;
}

.ScorePopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ScorePopup-Content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.8);
  height: 260px;
  width: 450px;
  font-weight: 500;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  overflow: hidden;
  
  @media (max-width: 449px){
    width: 330px;
    font-size: 18px;
    text-align: center;
    height: 270px;
  }

  @media (min-width: 450px){
    width: 450px;
    height: 260px;
  }
}

.ScorePopup-ContentInner {
  background-color: #8084c8;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  width: 450px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  transform: translateY(-22px);
  overflow: hidden;
}

.ScorePopupButtons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.ScoreSvgInline {
  display: flex;
  justify-content: center;
}