@font-face {
  font-family: "SF Pro";
  src: url(../font/SF-Pro.ttf) format("truetype");
  /* You can specify other font formats (e.g., woff, woff2) as needed */
}

.courseframewindow {
  position: absolute;
  top: calc(125px + 50%);
  left: 50%;
  transform: translate(-50%, -3%);
  width: 100vw;
  max-width: 96.6%;
  height: calc(79vh - 60px);
  overflow-y: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  border: 3px solid #696ca3;
  background: #fff;
  transition: width 0.4s ease, margin-left 0.4s ease;
}

.framewindow-container {
  /* Your existing styles */
  position: relative;
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  padding-bottom: 15px;
}

.courseframewindow.shrink {
  /* Your existing styles */
  align-items: center;
  width: calc(
    100vw - 300px
  ); /* Reduce the width by the width of the sssidebar */
  margin-left: 120px; /* Move the framewindow to the right to accommodate the sssidebar */
}

.coursetopictext {
  color: #696ca3;
  font-family: "SF Pro", sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 540;
  line-height: normal;
  transform: translate(27px, 5px);
  transition: transform 0.4s ease;
}

.datetext {
  color: #696ca3;
  font-family: "SF Pro", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  transform: translate(30px, 2px);
  transition: transform 0.4s ease;
}

.coursetopictext.moveRight {
  transform: translate(275px, 5px);
  transition: transform 0.4s ease;
}
.datetext.moveRight {
  transform: translate(280px, 2px);
  transition: transform 0.4s ease;
}

.frameEachCourse {
  top: 18px;
  width: 99vw;
  max-width: 98%;
  height: 80px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #7579c1;
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  text-indent: 40px;
  align-items: center;
  vertical-align: bottom;
}

.frameEachCourse:hover {
  top: 18px;
  width: 99vw;
  max-width: 98%;
  height: 80px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #575986;
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  text-indent: 40px;
  align-items: center;
  vertical-align: bottom;
}

.frameEachCourse:first-child {
  margin-top: 18px;
}

.frameEachCourse:last-child {
  margin-bottom: 18px;
}

.courseName {
  color: #ffffff;
  text-align: center;
  font-family: "SF Pro", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.intoCourse svg {
  position: absolute;
  right: 60px;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; /* Prevent the icon from interfering with mouse events */
  margin-top: 0px;
}

.AddCourseButton {
  flex-grow: 1;
  width: 190px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #ffffff;
  align-self: center;
  bottom: 110px;
  cursor: pointer;
  font-family: "SF Pro", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7579c1;
  border: #7579c1 3px solid;
  text-indent: -5px;
  font-size: 18px;
  transition: background-color 0.3s ease;
  position: fixed;
  right: calc(25px);
  top: 90px;
  cursor: pointer;
}

.AddCourseButton svg {
  position: absolute;
  left: 20px;
  top: 10px;
}

.AddCourseButton:hover {
  background-color: #7579c1;
  color: #ffffff;
}

.AddCourseButton:hover svg {
  /* background-color: aqua; */
  fill: #ffffff;
}

.MenuIndexLayout {
  margin-inline: 30px;
  width: 100vw;
  max-width: 96.6%;
  left: 50%;
  align-content: center;
}

.MenuNavigate {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  width: 100vw;
  max-width: 100%;
  left: 50%;
  transform: translate(0px, 0px);
}
.MenuNavigate.moveRight {
  width: calc(79vw);
  transform: translate(115px, 0px);
  transition: transform 0.4s ease;
  margin-inline: auto;
}

.lineIndex {
  height: 2px;
  width: 100vw;
  max-width: 100%;
  left: 50%;
  background-color: #8084c8;
  opacity: 0.25;
  box-shadow: 0px 10px 4px 0px rgba(0, 0, 0, 0.25) inset;
  margin-top: 5px;
  transform: translate(0px, 0px);
}

.lineIndex.moveRight {
  width: calc(79vw);
  transform: translate(115px, 0px);
  transition: transform 0.4s ease;
  margin-inline: auto;
}

.date {
  font-size: 19px;
  /* transition: transform 0.3s ease; */
}



.MenuIndex {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #696ca3;
  font-family: "SF Pro", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 510;
  line-height: normal;
}

.TitleLayout {
  margin-block: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.containerTitleDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Title {
  color: #ffffff;
  font-family: "SF Pro", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 590;
  line-height: normal;
  transform: translate(30px, -5px);
  transition: transform 0.8s ease;
}

.Date {
  color: #ffffff;
  font-family: "SF Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  transform: translate(30px, -3px);
  transition: transform 0.8s ease;
}

.Title.moveRight {
  transform: translate(30px, -5px);
}
.Date.moveRight {
  transform: translate(30px, -3px);
}

.ButtonTitleLayout {
  display: flex;
  align-items: top;
  justify-content: space-between;
}

.boxdrop {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
  gap: 20px;
}

.box_upload,
.manageButton,
.instructorButton {
  display: inline-flex;
  height: 18px;
  background: #8584c5;
  gap: 16px;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding: 12px 13px;
  border-radius: 20px;
  border: 3px solid #8584c5;
  cursor: pointer;
  font-family: "SF Pro", sans-serif;
  transition: background-color 0.3s ease;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  text-indent: -5px;
  transform: translateX(-25px);
}

.box_upload {
  width: 150px;
}

.manageButton {
  width: 150px;
}

.instructorButton {
  width: 120px;
}

.box_upload:hover {
  background-color: #d8dbe4;
  color: #000000;
}

.box_upload svg {
  transform: scale(1.1);
}

.box_upload.moveLeft .manageButton.moveLeft .instructorButton.moveLeft {
  transform: translate(-40px);
}

.manageButton:hover {
  background-color: #d8dbe4;
  color: #000000;
}

.manageButtonsvg {
  transform: scale(1.1);
}

.instructorButton:hover {
  background-color: #d8dbe4;
  color: #000000;
}

.instructorButtonsvg {
  transform: scale(1.1);
}

.showUpload {
  margin-top: 10px;
}

.AddCoursePopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-family: "SF Pro", sans-serif;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.AddCoursePopup-Content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.8);
  height: fit-content;
  width: 380px;
  font-weight: 500;
  font-family: "SF Pro", sans-serif;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  padding: 20px;
}

.AddCoursePopup-ContentInner {
  background-color: #8084c8;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  width: 440px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  transform: translateY(-20px);
  margin-bottom: 10px;
}

.DropDownContainer {
  transform: translateY(-40px);
  height: 10px;
  z-index: 1;
  font-family: "SF Pro", sans-serif;
}

.AddCoursePopupButtons {
  display: flex;
  font-family: "SF Pro", sans-serif;
  justify-content: center;
  margin-top: 9px;
}

.AddCourseCancelButton,
.AddCourseConfirmButton {
  cursor: pointer;
  font-family: "SF Pro", sans-serif;
}

.AddCourseCancelButton {
  width: 120px;
  height: 35px;
  border-radius: 10px;
  border: 1px;
  background: #fff;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  margin: 10px;
  margin-top: 5px;
  cursor: pointer;
  font-family: "SF Pro", sans-serif;
}

.AddCourseConfirmButton {
  width: 120px;
  height: 35px;
  border-radius: 10px;
  border: 1px;
  background: linear-gradient(180deg, rgba(36, 43, 176, 0.4) 0%, #535adf 100%);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  margin: 10px;
  margin-top: 5px;
  cursor: pointer;
  font-family: "SF Pro", sans-serif;
}

.AddCourseInlineContainer {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  transition: transform 0.3s ease;
  margin-top: -15px;
  font-family: "SF Pro", sans-serif;
}

.frameCourseDash {
  font-family: "SF Pro", sans-serif;
  position: absolute;
  top: calc(75px + 50%);
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100vw;
  max-width: 96.6%;
  height: calc(87vh - 70px);
  flex-direction: column;
  border-radius: 25px;
  border: 4px solid #9f9eda;
  background: #fff;
  align-items: center;
  display: flex;
  border-radius: 20px 20px 20px 20px;
  transition: width 0.4s ease, margin-left 0.4s ease;
}

.frameCourseDash.shrink {
  align-items: center;
  width: calc(79vw);
  margin-left: 122px;
}

.navbarCourseDash {
  position: relative;
  top: -3px;
  left: 0;
  width: 97vw;
  height: 80px;
  display: flex;
  justify-content: space-between; /* Align elements to the right */
  align-items: center; /* Vertically center align elements */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border: 3px;
  background: #8584c5;
  z-index: 1;
  transition: width 0.4s ease, margin-left 0.4s ease;
}

.navbarCourseDash.shrink {
  width: calc(79.3vw);
}

.desText {
  color: #696ca3;
  text-align: center;
  font-family: "SF Pro", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 590;
  line-height: normal;
}

.ScoreCancelButton {
  transform: translate(40px, 45px);
  width: 150px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 10px;
  border: #cd5340 solid 2px;
  background: #ffffff;
  bottom: 110px;
  cursor: pointer;
  display: flex;
  justify-content: right;
  align-items: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-indent: -35px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.ScoreCancelButtonInner {
  width: 45px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 0px 5px 5px 0px;
  background: #d13e10;
  bottom: 110px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
  text-indent: -15px;
  font-size: 20px;
  transition: background-color 0.3s ease;
}

.ScoreCancelButton:hover {
  background: #d13e10;
  transition: background-color 0.3s ease;
}

.ScoreCancelButton:hover .ScoreCancelText {
  color: #ffffff;
}

.ScoreCancelText {
  position: absolute;
  right: 65px;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  color: #cd5340;
  transition: color 0.3s ease;
}

.ScoreConfirmButton {
  transform: translate(60px, 45px);
  width: 150px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 10px;
  border: #1b7744 solid 2px;
  background: #ffffff;
  bottom: 110px;
  cursor: pointer;
  display: flex;
  justify-content: right;
  align-items: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-indent: -35px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.ScoreConfirmButtonInner {
  width: 45px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 0px 5px 5px 0px;
  background: #23834b;
  bottom: 110px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
  text-indent: -15px;
  font-size: 20px;
  transition: background-color 0.3s ease;
}

.ScoreConfirmText {
  position: absolute;
  right: 65px;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  color: #23834b;
  transition: color 0.3s ease;
}

.ScoreConfirmButton:hover {
  background: #23834b;
  transition: background-color 0.3s ease;
}

.ScoreConfirmButton:hover .ScoreConfirmText {
  color: #ffffff;
}

.ScorePopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ScorePopup-Content {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.8);
  height: fit-content;
  width: 490px;
  font-family: "SF Pro", sans-serif;
  font-weight: 500;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

.ScorePopup-ContentInner {
  background-color: #8084c8;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  width: 490px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  transform: translateY(-40px);
  font-family: "SF Pro", sans-serif;
}

.instructorPopupButtons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  font-family: "SF Pro", sans-serif;
}

.AddPopupButton {
  width: 140px;
  height: 35px;
  border-radius: 10px;
  border: 1px;
  background: linear-gradient(180deg, rgba(36, 43, 176, 0.4) 0%, #535adf 100%);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  margin: 0 10px;
  cursor: pointer;
  font-family: "SF Pro", sans-serif;
  /* transform: translateY(10px); */
}

.CancelPopupButton {
  width: 120px;
  height: 35px;
  border-radius: 10px;
  border: 1px;
  background: #fff;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  margin: 0 10px;
  cursor: pointer;
  font-family: "SF Pro", sans-serif;
  /* transform: translateY(10px); */
}

.ScoreSvgInline {
  display: flex;
  justify-content: center;
}


.errorMessage {
  color: red;
  font-size: 14px;}